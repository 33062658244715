// @flow

import React, { Component } from 'react'
import { Marker } from 'react-leaflet';
import { DivIcon } from "leaflet";
import {getLocationsForNow} from "./LocationsManager";
export const prayerIcon = (content) => {
  return new DivIcon({
     iconSize:null,
     html:'<div class="map-label"><div class="map-label-content">'+content+'</div><div class="map-label-arrow"></div></div>'
   });
  }
export default class PrayerPopups extends Component {
  render() {
      var pins = this.props.prayerLocations;
      if (!pins || pins.length === 0) {
        pins = getLocationsForNow();
      }
      console.log(pins);
      var markers = pins.map(
        pl => (
        <Marker
          key={pl.cleanName1}
          position={[
            pl.latitude,
            pl.longitude
          ]}
          icon={prayerIcon(pl.cleanName1)}
        />
      ));
        return markers;
  }

}