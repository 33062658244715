import React from "react";
import { Map, TileLayer } from "react-leaflet";
import getParameterByName from "./utilities/parseQueryParameters.js";
import {getLocationLookup} from "./components/LocationsManager.js";
import PrayerPopups from "./components/PrayerPopups.js";
import "./App.css";

export default function App() {
  const pinsParameter = (getParameterByName('pins') || '').split(';')
  const locationLookup = getLocationLookup();
  const pins = pinsParameter
  .filter(p => p)
  .map(pinParameter => {
    return locationLookup[pinParameter];
  })
  .map(p => p)
  .filter(p => p);
  return (
    <Map center={[10, 10]} zoom={2} >
      <TileLayer
        url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png"
        subdomains={["a", "b", "c", "d"]}
        opacity={0.40}
        attribution='&copy; Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.'
      />
      <PrayerPopups prayerLocations={pins} />
    </Map>
  );
}
