import prayerMatrixLocations from "../data/matrixLocations.json";

var _locationLookup = null;
const regex = /\d\.\s+/gi;
const getLocationLookup = () => {
  if (_locationLookup != null)
    return _locationLookup
  const locationLookup = {};
  prayerMatrixLocations.locations.filter(
    dayForPrayer => dayForPrayer.length > 0
  ).forEach(
    dayForPrayer => {
      dayForPrayer.forEach(
        hourInDay => {
          hourInDay.forEach(locationToPrayFor => {
            const locationInfo = createLocationObjectFromListEntry(locationToPrayFor);
            locationLookup[locationToPrayFor[0]] = locationInfo;
            locationLookup[locationToPrayFor[1]] = locationInfo;
            locationLookup[locationToPrayFor[2]] = locationInfo;
            locationLookup[locationInfo.originalCellName] = locationInfo;
            locationLookup[locationInfo.cleanName1] = locationInfo;
            locationLookup[locationInfo.cleanName2] = locationInfo;
          });
        });
    });
  _locationLookup = locationLookup;
  return locationLookup
};
function createLocationObjectFromListEntry (locationToPrayFor) {
  return {
    originalCellName: locationToPrayFor[0].replace(regex, "").trim(),
    cleanName1: locationToPrayFor[0].replace(regex, "").trim(),
    cleanName2: locationToPrayFor[0].replace(regex, "").trim(),
    longitude: locationToPrayFor[3],
    latitude: locationToPrayFor[4]
  };
}
const getLocationsForNow = () => {
  return getLocationsByTime(new Date());
}

const getLocationsByTime = (pointInTime) => {
  // the month starts at 0, so subtract 1 from a typical month number
  const { daysPast, hoursPast } = getDaysAndHoursPast(pointInTime);
  console.log(daysPast, hoursPast);
  if (prayerMatrixLocations.locations.length <= daysPast) {
    return null;
  }
  let daysEntries = prayerMatrixLocations.locations[daysPast];
  if (daysEntries.length === 0) {
    return null;
  } else if (daysEntries.length >= hoursPast) {
    return daysEntries[hoursPast].map(locationToPrayFor => createLocationObjectFromListEntry(locationToPrayFor));
  } else {
    return daysEntries[daysEntries.length -1].map(locationToPrayFor => createLocationObjectFromListEntry(locationToPrayFor));
  }
}

function getDaysAndHoursPast(pointInTime) {
  const daySpan = 40;
  const startTime = new Date(2020, 3, 23, 0, 0);
  const endTime = addDays(startTime, daySpan);
  // const currentSecond = new Date().getTime();
  // find the percentage along the range
  const progress = (pointInTime - startTime) / (endTime - startTime);
  console.log(progress);
  // find the day (0 - daySpan) this belongs to
  const singleDayPercentage = (1 / daySpan);
  const singleHourPercentage = (1 / 24) * singleDayPercentage;
  console.log(singleDayPercentage, singleHourPercentage);
  const daysPast = Math.floor(progress / singleDayPercentage);
  // find the hour it belongs to (using the difference)
  const hoursRemainingProgress = progress - (daysPast * singleDayPercentage);
  const hoursPast = Math.floor(hoursRemainingProgress / singleHourPercentage);
  return { daysPast, hoursPast };
}
function addDays(date, days) {
  var newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days)
  return newDate;
}




export { getLocationLookup, getLocationsByTime, getLocationsForNow };